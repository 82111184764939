import styled from "styled-components";
import { Container as Wrapper } from "../../styles/UI";

export const Title = styled.h2`
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 36px;
  text-align: center;
  margin-bottom: 24px;
  color: ${(p) => p.theme.primary};
`;

export const Hero = styled.header`
  /* max-height: 986px; */
  width: 100%;
  background: ${(p) => p.theme.degradeAzul};
  margin-top: -180px;
  padding-top: 180px;
  padding-bottom: 0;
  color: var(--color-white);
  box-sizing: border-box;
  overflow: hidden;

  @media (min-width: ${(p) => p.theme.bp.veryBigDesktop}) {
    overflow: ${(p) => (p.$clip ? "hidden" : "visible")};
  }
`;

export const Container = styled(Wrapper)`
  .cta {
    position: relative;
    z-index: 10;

    h1 {
      font-size: 36px;
      font-weight: 600;
      letter-spacing: -0.01em;
      margin-bottom: 48px;
      line-height: 1.25;
      padding-top: 60px;
    }

    p {
      font-weight: 350;
      font-size: 20px;
      line-height: 32px;
      margin-bottom: 24px;
    }
  }

  .illustration {
    z-index: 1;
    margin-top: 32px;
    position: relative;

    .bg-logo {
      position: absolute;
      z-index: 0;
      height: 800px;
      right: -180px;
      top: -440px;

      @media (min-width: ${(p) => p.theme.bp.tablet}) {
        left: 60px;
        top: -540px;
        height: 980px;
      }
    }
  }

  /* Button on Hero */
  & button {
    box-shadow: 0px 8px 16px -4px #05185b;
  }

  & button:hover {
    background-color: var(--color-white);
  }

  @media (min-width: ${(p) => p.theme.bp.tablet}) {
    max-height: 820px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 32px;
    align-items: center;
  }
`;

export const Branco = styled.section`
  padding: 48px;
  text-align: center;

  .paralax {
    display: none;
  }

  .iframe-container {
    margin: 0 auto;
    position: relative;
    width: calc(100% - 32px);
    border-radius: 0px 20px;
    height: 0;
    /* 16:9 */
    padding-top: 56.25%;
    /* 3:4 */
    /* padding-top: 75%; */
    align-self: center;
    overflow: hidden;

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 100%;
      padding: 0;
      box-sizing: border-box;
    }
  }

  @media (min-width: ${(p) => p.theme.bp.desktop}) {
    padding: 120px 240px;
  }

  @media (min-width: ${(p) => p.theme.bp.veryBigDesktop}) {
    ${Container} {
      position: relative;
    }

    .paralax {
      display: block;
      position: absolute;
      left: 0;
      top: 500px;
    }
  }
`;

export const MaisVideos = styled.section`
  padding: 64px;

  
  h2 {
    font-weight: 600;
    font-size: 36px;
    line-height: 56px;

    text-align: center;
    letter-spacing: -1px;
    background: ${(p) => p.theme.degradeMisto2};
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  @media (min-width: ${(p) => p.theme.bp.tablet}) {
    line-height: 42px;

    span {
      font-size: 50px;
    }
  }
`;

export const TaxSheets = styled.section`
  padding: 64px;
  background: linear-gradient(127.23deg, #00458e 28.41%, #000093 83.13%);

  .logo {
    text-align: center;
    margin-bottom: 80px;

    img {
      width: 240px;
    }

    p {
      font-weight: 600;
      font-size: 20px;
      line-height: 24px;
      color: ${(p) => p.theme.branco};
      margin-top: 16px;
    }
  }

  .iframe-container {
    position: relative;
    width: calc(100% - 32px);
    border-radius: 0px 20px;
    height: 0;
    /* 16:9 */
    padding-top: 56.25%;
    /* 3:4 */
    /* padding-top: 75%; */
    align-self: center;
    overflow: hidden;

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 100%;
      padding: 0;
      box-sizing: border-box;
    }
  }

  .conteudo {
    font-weight: 350;
    font-size: 17px;
    line-height: 29px;
    color: ${(p) => p.theme.branco};

    img.taxsheets {
      display: none;
    }

    img.taxdashs {
      display: none;
    }
  }

  .texto {
    h3 {
      font-weight: 600;
      font-size: 17px;
      line-height: 24px;
      color: ${(p) => p.theme.branco};
      margin-bottom: 24px;
    }

    p {
      margin-bottom: 16px;
    }

    .buttons {
      font-size: 16px;
      margin-top: 48px;
    }
  }

  @media (min-width: ${(p) => p.theme.bp.tablet}) {
    .logo {
      img {
        width: 340px;
      }
    }

    .conteudo {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 96px;

      .video {
        grid-column: 1;
        grid-row: 1;
        position: relative;
        /* overflow: hidden; */

        img.taxsheets {
          display: block;
          position: absolute;
          height: 600px;
          top: -80px;
          left: -280px;
        }

        img.taxdashs {
          display: block;
          position: absolute;
          height: 600px;
          top: -60px;
          right: -220px;
        }
      }

      .column2 {
        grid-column: 2;
      }

      .texto {
        grid-row: 1;

        .buttons {
          display: grid;
          grid-template-columns: 1fr 1fr;
          gap: 32px;
          justify-items: flex-start;
          align-items: baseline;
        }
      }
    }
  }
`;
