import React, { useEffect, useLayoutEffect, useRef, useState } from "react";

import Button from "../../DesignSystem/Button";
import StyledLink from "../../DesignSystem/StyledLink";
import { Container } from "../../styles/UI";

import Layout from "../../components/Layout";
import Seo from "../../components/Seo";

import paralax from "../../images/paralax.svg";

import Apresentacao from "../../pagesFiles/home/apresentacao";

import logoTaxDashs from "../../images/taxdashs/taxdashs-logo-negative.svg";
import logoTaxSheets from "../../images/taxsheets/taxsheets-logo-negative.svg";

import * as S from "./style";

import taxsheetsIllustration from "../../images/taxsheets-apresentacao.png";
import taxdashsIllustration from "../../images/taxdashs-apresentacao.png";

import logoBgNegative from "../../images/home/logo-negative.svg";

import { navigate } from "gatsby-link";
import Modal from "../../DesignSystem/Modal";
import FormInput from "../../DesignSystem/FormInput";
import { useInput } from "../../hooks/useInput";

import { useViewportScroll, motion, useTransform } from "framer-motion";

import axios from "axios";

export default function Demonstracao() {
  const [isVisible, setIsVisible] = useState(true);

  const form = new URLSearchParams();

  const clientRD = axios.create({
    baseURL: `https://taxcellicenseserver2.azurewebsites.net/`,
    headers: {
      "Content-Type": `application/x-www-form-urlencoded`,
    },
  });

  const modalRef = useRef();

  const nomeRef = useRef();

  const {
    value: email,
    setValue: setEmail,
    isInvalid: isInvalidEmail,
    bind: bindEmail,
    reset: resetEmail,
  } = useInput("", "email");
  const {
    value: nome,
    isInvalid: isInvalidNome,
    bind: bindNome,
    reset: resetNome,
  } = useInput("");
  const {
    value: tel,
    isInvalid: isInvalidTel,
    bind: bindTel,
    reset: resetTel,
  } = useInput("", "tel");

  const ref = useRef();
  const [offsetTop, setOffsetTop] = useState(0);

  useLayoutEffect(() => {
    if (!ref.current) return null;
    let bodyRect = document.body.getBoundingClientRect();
    let elementRect = ref.current.getBoundingClientRect();
    setOffsetTop(elementRect.top - bodyRect.top);
  }, [ref]);

  const { scrollY } = useViewportScroll();
  const y1 = useTransform(
    scrollY,
    [offsetTop - 600, offsetTop + 600],
    ["0%", "100%"]
  );

  useEffect(() => {
    modalRef.current.openModal();
    return () => {};
  }, []);

  useEffect(() => {
    // if (isVisible) nomeRef.current.focus();
    console.log(nomeRef.current);
    console.log(isVisible);
  }, [isVisible]);

  const sendForm = () => {
    if (
      !nome ||
      isInvalidNome ||
      !email ||
      isInvalidEmail ||
      !tel ||
      isInvalidTel
    )
      return;

    let nomeMax35 = nome;

    if (nome.length > 35) {
      nomeMax35 = nome.substring(0, 35);
    }

    form.append("source", "demonstracao");
    form.append("email", email);
    form.append("telefone", tel);
    form.append("nome", nomeMax35);

    clientRD.post(`/RD_Station.aspx`, form).then((resp) => {
      console.log(resp);
      console.log(form);
    });

    modalRef.current.closeModal();
  };

  const handleClick = () => {
    navigate("#taxsheets");
  };

  const handleDisplayChange = (display) => {
    setIsVisible(display);
  };

  return (
    <Layout>
      <Modal cantClose onDisplayChange={handleDisplayChange} ref={modalRef}>
        <S.Title>Preencha seus dados para acessar a página</S.Title>
        <FormInput
          required
          name="nome"
          label="Nome"
          mt={16}
          ref={nomeRef}
          placeholder="Nome Sobrenome"
          error={isInvalidNome}
          errorMsg="Campo obrigatório"
          {...bindNome}
        />
        <FormInput
          required
          name="email"
          label="Email"
          type="email"
          mt={16}
          placeholder="nome@email.com"
          error={isInvalidEmail}
          errorMsg="Digite um email válido para continuar"
          {...bindEmail}
        />
        <FormInput
          required
          name="tel"
          type="tel"
          label="Telefone"
          mt={16}
          placeholder="(__) _____-____"
          error={isInvalidTel}
          errorMsg="Digite um telefone válido para continuar"
          {...bindTel}
        />
        <Button fluid fullWidth center mt={32} onClick={() => sendForm()}>
          Acessar página
        </Button>
      </Modal>
      <Seo title="Contato" />
      <S.Hero>
        <S.Container>
          <div className="cta">
            <h1>Vídeo Demonstração das Soluções</h1>
            <p>
              Assista ao vídeo demonstração das nossas ferramentas e tire suas
              principais dúvidas de forma rápida e interativa!
            </p>
            <p>
              Acesse as gravações abaixo, economize tempo e vá direto ao assunto
              de interesse através dos marcadores nos vídeos.{" "}
            </p>
            <p>
              Agende uma apresentação ao vivo, solicite uma proposta
              personalizada ou faça o Trial Gratuito!
            </p>
            <Button
              onClick={() => navigate("#taxsheets")}
              mt={48}
              bg="secondary"
              color="textOnGreen"
            >
              Demonstração TaxSheets
            </Button>
            <Button
              onClick={() => navigate("#taxdashs")}
              mt={24}
              mb={64}
              bg="taxdashs"
              color="textOnYellow"
            >
              Demonstração TaxDashs 
            </Button>
          </div>
          <div className="illustration">
          </div>
        </S.Container>
      </S.Hero>

      <S.Branco>
        <Container>
          <motion.img
            ref={ref}
            style={{ y: y1 }}
            src={paralax}
            className="paralax"
            alt=""
          />
          <div className="iframe-container">
            <iframe
              src="https://www.youtube.com/embed/vgxcIu0-aY8"
              title="4 Perguntas para a Taxcel - Vídeo Institucional"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
        </Container>
      </S.Branco>

      <S.TaxSheets id="taxsheets">
        <Container>
          <div className="logo">
            <img src={logoTaxSheets} alt="logo TaxSheets" />
            <p>Leitor e Editor de SPED</p>
          </div>
          <div className="conteudo">
            <div className="video">
              <img className="taxsheets" src={taxsheetsIllustration}></img>
              <div className="iframe-container">
                <iframe
                  src="https://www.youtube.com/embed/Z5IxgQg5lmM"
                  title="video-taxsheets"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
              </div>
            </div>
            <div className="texto">
              <h3>
                Transforme arquivos fiscais do SPED em planilhas editáveis do
                Excel e dê um salto na sua produtividade.
              </h3>
              <p>
                Com o TaxSheets você reduz drasticamente seus problemas com:
              </p>
              <ul>
                <li>Processos manuais e repetitivos</li>
                <li>Mudanças de regras fiscais</li>
                <li>Sistemas ERP pouco flexíveis</li>
                <li>Atrasos, erros e multas</li>
              </ul>
              <div className="buttons">
                <Button bg="degrade2" onClick={() => navigate("/contato")}>
                  Comprar TaxSheets
                </Button>
                <StyledLink color="branco" to="/baixar-taxsheets">
                  Teste gratuitamente por 7 dias
                </StyledLink>
              </div>
            </div>
          </div>
        </Container>
      </S.TaxSheets>
      <Apresentacao />

      <S.TaxSheets id="taxdashs">
        <Container>
          <div className="logo">
            <img src={logoTaxDashs} alt="logo TaxSheets" />
            <p>BI para Tax</p>
          </div>
          <div className="conteudo">
            <div className="video column2">
              <img className="taxdashs" src={taxdashsIllustration}></img>
              <div className="iframe-container">
                <iframe
                  src="https://www.youtube.com/embed/ZyW95F_Lb88"
                  title="video-taxdashs"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
              </div>
            </div>
            <div className="texto">
              <h3>
                Visualize as suas informações fiscais em relatórios interativos
                no Power BI e obtenha insights valiosos para sua empresa.
              </h3>
              <p>
                O TaxDashs te ajuda a inovar na gestão tributária e resolver
                problemas como:
              </p>
              <ul>
                <li>Descontrole dos relatórios fiscais da sua empresa</li>
                <li>Falta de indicadores para tomada de decisões</li>
                <li>
                  Alto custo e pouco valor agregado do departamento fiscal
                </li>
                <li>
                  Dificuldade em implementar uma solução de data analytics
                  aplicado a tax
                </li>
              </ul>
              <div className="buttons">
                {/* <StyledLink color="branco" to="https://azuremarketplace.microsoft.com/pt-br/marketplace/apps/taxcel.taxdashs?tab=overview">
                  Teste gratuitamente por 15 dias
                </StyledLink> */}
                <StyledLink color="branco" to="https://taxcelcloud.com/teste">
                  Teste gratuitamente por 15 dias
                </StyledLink>
                <Button
                  bg="degrade2"
                  onClick={() =>
                    navigate("/contato", {
                      state: { msg: "demonstracao", produto: "taxdashs" },
                    })
                  }
                >
                  Solicitar proposta
                </Button>
              </div>
            </div>
          </div>
        </Container>
      </S.TaxSheets>

      <S.MaisVideos>
        <Container>
          <h2>Inicie hoje sua jornada no Tax Transformation</h2>
        </Container>
      </S.MaisVideos>
    </Layout>
  );
}
